import "../scss/style.scss";

require("./library/modernizr");
require("./library/detectizr");

require("what-input");

const jQuery = require("jquery");
window.$ = window.jQuery = jQuery;

let _debug = require("./library/debug");

import "foundation-sites";

require("owl.carousel");
require("./library/imagelightbox");

let NodesManagerFunctions = function () {
  this.bodyNode = $("body");
};

NodesManagerFunctions.prototype.init = function () {
  this.getNodes();
};

NodesManagerFunctions.prototype.getNodes = function () {
  let self = this;
  $("[data-function]")
    .add("[data\\:function]")
    .not(".hasBeenBound")
    .each(function () {
      let node = $(this),
        command = node.data("function"),
        options = node.data("options") || "";

      if (command != null) {
        let functions = command.split(" ");
        $.each(functions, function (i, v) {
          if (typeof self[v] == "function") {
            if (options != null && options != "") {
              if (typeof options == "string") {
                let fields = options.split(", ");
                if (typeof fields == "object" && fields != "") {
                  let data = new Function("return" + options);
                  options = data();
                } else {
                  options = "";
                }
              }

              self[v].apply(self, [node, options]);
            } else {
              self[v].apply(self, [node, options]);
            }
          } else {
            _debug.log("function " + v + " not found, called by ", node);
          }
        });
      } else {
        _debug.log("function " + command + " not found, called by ", node);
      }

      node.addClass("hasBeenBound");
    });
};

/*** Canvas Function ***/
NodesManagerFunctions.prototype.createOffCanvas = function (node) {
  let createOffCanvas = {
    eNode: null,
    offcanvas: null,
    offcanvasOpenClass: "position-left",
    is_canvas_created: false,
    nav: null,
    nav_lang: null,
    canvasNav: null,
    canvasNavLang: null,
    offCanvasInner: $('<div class="bb-canvas--inner"/>'),
    eTrigger: $(
      '<button class="bb-canvas--button" type="button"><span class="bb-canvas--button__bar"></span><span class="bb-canvas--button__label"> Open Canvas Menu </span></button>'
    ),
    canvasNavWrap: $(
      '<div class="off-canvas bb-canvas" id="offCanvas" data-off-canvas data-transition="overlap" />'
    ),
  };

  createOffCanvas.init = function (node) {
    this.eNode = node;
    if (
      $(window).outerWidth() < 1024 ||
      $("html").hasClass("mobile") ||
      $("html").hasClass("tablet") ||
      ($(window).width() > 1024 && $("html").hasClass("tablet"))
    ) {
      this.createCanvas();
      this.is_canvas_created = true;
    }
    this.checkWidth();
  };

  createOffCanvas.checkWidth = function () {
    let _self = this;
    $(window).on("resize", function () {
      if (
        $(window).outerWidth() < 1024 ||
        $("html").hasClass("mobile") ||
        $("html").hasClass("tablet") ||
        ($(window).width() > 1024 && $("html").hasClass("tablet"))
      ) {
        _self.createCanvas();
        _self.is_canvas_created = true;
      } else if (
        $(window).outerWidth() > 1024 &&
        !$("html").hasClass("tablet")
      ) {
        _self.destroyCanvas();
        _self.is_canvas_created = false;
      }
    });
  };

  createOffCanvas.copyNav = function () {
    this.nav = this.eNode.find(".bb-header--nav");
    this.nav_lang = this.eNode.find(".bb-header--nav-lang");
    this.canvasNav = this.nav.clone();
    this.canvasNavLang = this.nav_lang.clone();
    this.eNode
      .find(".bb-canvas--inner")
      .append(
        this.canvasNav.removeClass("bb-header--nav").addClass("bb-canvas--nav"),
        this.canvasNavLang
          .removeClass("bb-header--nav-lang")
          .addClass("bb-canvas--nav-lang")
      );
    this.nav.remove();
    this.nav_lang.remove();
  };

  createOffCanvas.iniFoundationOffCanvas = function () {
    this.offcanvas = new Foundation.OffCanvas($("#offCanvas"));
  };

  createOffCanvas.setEvents = function () {
    let _self = this;
    this.eNode.find(".bb-canvas--button").bind("click", function (e) {
      e.preventDefault();
      _self.offcanvas.toggle();
    });
  };

  createOffCanvas.createCanvas = function () {
    if (!this.is_canvas_created) {
      this.eNode.find(".bb-header").append(this.eTrigger);
      this.canvasNavWrap.append(this.offCanvasInner);
      this.eNode.append(this.canvasNavWrap.addClass(this.offcanvasOpenClass));
      this.eNode
        .find(".bb-header, .bb-main, .bb-footer")
        .wrapAll(
          '<div class="off-canvas-content" data-off-canvas-content></div>'
        );
      this.copyNav();
      this.iniFoundationOffCanvas();
      this.setEvents();
    }
  };

  createOffCanvas.destroyCanvas = function () {
    if (this.is_canvas_created) {
      this.canvasNav.remove();
      this.canvasNavLang.remove();
      this.eNode.find(".bb-canvas--button").remove();
      this.eNode.find(".js-off-canvas-overlay").remove();
      this.eNode.find(".off-canvas").remove();
      this.eNode.find(".bb-header, .bb-main, .bb-footer").unwrap();
      this.eNode.find(".bb-canvas--button").unbind();
      this.offcanvas = null;
      this.eNode
        .find(".bb-header .bb-container")
        .append(this.nav_lang, this.nav);
    }
  };

  createOffCanvas.init(node);
};

/*** Sticky Color ***/
NodesManagerFunctions.prototype.stickyHeader = function (node) {
  let stickyHeader = {
    eNode: null,
    hedaer: null,
    header_height: null,
    is_sticky: false,
    is_changed: false,
  };

  stickyHeader.init = function (node) {
    this.eNode = node;
    this.header = this.eNode.find(".bb-header");
    this.header_height = this.header.outerHeight();
    if (
      ($(window).outerWidth() > 1024 && $("html").hasClass("desktop")) ||
      ($(window).outerWidth() > 1024 && $("html").hasClass("tablet") == false)
    ) {
      this.is_sticky = true;
    }
    if (window.pageYOffset > this.header_height) {
      this.stickyClass();
    }
    this.scrollEvent();
    this.checkWidth();
  };

  stickyHeader.checkWidth = function () {
    let _self = this;
    $(window).on("resize", function () {
      if (
        ($(window).outerWidth() > 1024 && $("html").hasClass("desktop")) ||
        ($(window).outerWidth() > 1024 && $("html").hasClass("tablet") == false)
      ) {
        _self.is_sticky = true;
      } else {
        _self.is_sticky = false;
      }
    });
  };

  stickyHeader.scrollEvent = function () {
    let _self = this;
    $(window).on("scroll", function () {
      let scroll_value = $(window).scrollTop();
      if (scroll_value > _self.header_height) {
        _self.stickyClass();
      } else if (scroll_value < _self.header_height) {
        _self.removeStickyClass();
      }
    });
  };

  stickyHeader.stickyClass = function () {
    if (!this.is_changed && this.is_sticky) {
      this.header.addClass("is-sticky");
      this.is_changed = true;
    }
  };

  stickyHeader.removeStickyClass = function () {
    if (this.is_changed && this.is_sticky) {
      this.is_changed = false;
      this.header.removeClass("is-sticky");
    }
  };

  stickyHeader.init(node);
};
/*** SmoothScroll to anchor ***/
NodesManagerFunctions.prototype.smoothScroll = function (node) {
  let smoothScroll = {
    eNode: null,
    header_height: null,
  };

  smoothScroll.init = function (node) {
    this.eNode = node;
    this.scrollEvent();
  };

  smoothScroll.scrollEvent = function () {
    this.header_height = this.eNode.outerHeight();
    let _self = this;
    let home = $("body").hasClass("bb-home") ? true : false;
    $("[data-location]").on("click", function (event) {
      if (this.hash !== "") {
        event.preventDefault();
        let hash = $(this).data("location");
        if (home) {
          _self.scrollToDestination(hash);
        } else {
          if (window.location.href.indexOf("/en/") != -1) {
            window.location = "/en#scroll:" + hash;
          } else {
            window.location = "/#scroll:" + hash;
          }
        }
      }
    });
    if (window.location.hash) {
      let hash = decodeURIComponent(window.location.hash),
        desitnation = hash.replace("#scroll:", "");
      _self.scrollToDestination(desitnation);
    }
  };

  smoothScroll.scrollToDestination = function (aim) {
    if ($('[data-destination="' + aim + '"]').length > 0) {
      $("html, body").animate(
        {
          scrollTop:
            $('[data-destination="' + aim + '"]').offset().top -
            this.header_height,
        },
        900
      );
      window.location.hash = aim;
    }
  };

  smoothScroll.init(node);
};

/*** Video Control remove autoplay only on mobile ***/
NodesManagerFunctions.prototype.videoControl = function (node) {
    let videoControl = {
        eNode: null,
    }

    videoControl.init = function (node) {
        this.eNode = node;
        this.playVideo();
    }

    videoControl.playVideo = function () {
      const video = this.eNode.find('.bb-media--video');

      if ($('html').hasClass('mobile')) {
          $('.bb-cover__video-play').on('click', function () {
              this.remove();
              video[0].play();
          })
      } else {
          setTimeout(function() {
              video[0].play();
          }, 100);
      }
    }
  videoControl.init(node);
}

/*** Change Backgound Colors -- inverseReverse ***/
NodesManagerFunctions.prototype.colorReverse = function (node) {
  let colorReverse = {
    eNode: null,
    viewport_height: null,
    changing_edge: null,
    elements_change: null,
    first: null,
    ready_to_change: null,
  };

  colorReverse.init = function (node) {
    this.eNode = node;
    this.viewport_height = $(window).height();
    this.changing_edge = this.viewport_height / 2;
    this.resizeEvent();
    this.grabAllElements();
  };

  colorReverse.grabAllElements = function () {
    this.elements_change = $(".bb-inverse");
    if (this.elements_change.length > 1) {
      this.changeVisitedElements();
      this.changeColorsOnScroll();
    }
  };

  colorReverse.changeVisitedElements = function () {
    let _self = this,
      visited_page_height = window.pageYOffset;
    this.elements_change.each(function () {
      _self.first = _self.elements_change.eq(0);
      _self.ready_to_change = true;
      let element_position = _self.first.offset().top - visited_page_height;
      if (element_position <= _self.changing_edge && _self.ready_to_change) {
        _self.removeColor();
      }
    });
  };

  colorReverse.changeColorsOnScroll = function () {
    let _self = this;
    _self.first = _self.elements_change.eq(0);
    _self.ready_to_change = true;
    $(window).on("scroll", function () {
      let touch_edge = _self.first.offset().top - $(window).scrollTop();
      if (touch_edge <= _self.changing_edge && _self.ready_to_change) {
        _self.removeColor();
      }
    });
  };

  colorReverse.removeColor = function () {
    this.ready_to_change = false;
    this.first.removeClass("bb-inverse");
    if (this.elements_change.length > 1) {
      this.elements_change.splice(0, 1);
      this.first = this.elements_change.eq(0);
      this.ready_to_change = true;
    }
  };

  colorReverse.resizeEvent = function () {
    let _self = this;
    $(window).on("resize", function () {
      _self.viewport_height = $(window).height();
      _self.changing_edge = _self.viewport_height / 2;
      _self.changeVisitedElements();
    });
  };

  colorReverse.init(node);
};

/*** Calc Card's Details Height --- Hide Text  ***/
NodesManagerFunctions.prototype.cardDetails = function (node) {
  let cardDetails = {
    eNode: null,
  };

  cardDetails.init = function (node) {
    this.eNode = node;
    this.checkDevice();
    this.resizeEvent();
  };

  cardDetails.checkDevice = function () {
    let isDesktop = $("html").hasClass("desktop") ? true : false;
    if ($(window).outerWidth() > 1199 && isDesktop) {
      this.cardCalculation();
    } else {
      this.removeCardAnimation();
    }
  };

  cardDetails.resizeEvent = function () {
    let _self = this;
    $(window).on("resize", function () {
      _self.checkDevice();
    });
  };

  cardDetails.cardCalculation = function () {
    let items = this.eNode.find(".bb-card");
    items.each(function () {
      $(this).addClass("bb-card__animation");
      let details = $(this).find(".bb-card--details"),
        abstract = details.find("p"),
        abstractHeight =
          abstract.innerHeight() != null ? abstract.innerHeight() * -1 : 0;
      details.css("bottom", abstractHeight);
    });
  };

  cardDetails.removeCardAnimation = function () {
    let items = this.eNode.find(".bb-card");
    items.each(function () {
      $(this).removeClass("bb-card__animation");
      let details = $(this).find(".bb-card--details");
      details.css("bottom", "0");
    });
  };

  cardDetails.init(node);
};

/*** Big Slider ***/
NodesManagerFunctions.prototype.coverSlider = function (node) {
  let autoplay = node.data("autoplay"),
    autoplay_timeout = node.data("timeout"),
    details_highs = false,
    dots_position = false,
    loop = node.data("loop");

  node.find(".owl-carousel").owlCarousel({
    items: 1,
    loop: loop,
    autoplayHoverPause: true,
    autoplayTimeout: autoplay_timeout,
    autoplaySpeed: 200,
    navs: false,
    autoplay: autoplay,
    info: true,
  });

  node.find(".owl-carousel").on("resized.owl.carousel", setDotsPosition);

  let details_elements = node.find(".bb-slider--details"),
    dots_element = node.find(".owl-dots");

  setDotsPosition();

  function setDotsPosition() {
    if ($("html").hasClass("mobile")) {
      details_highs = [];
      details_elements.each(function () {
        let details_high = $(this).outerHeight();
        details_highs.push(details_high);
      });
      dots_position = Math.max.apply(Math, details_highs);
      dots_element.css("bottom", "" + dots_position + "px");
    } else {
      dots_element.css("bottom", "");
    }
  }
};

/*** Lightbox ***/
NodesManagerFunctions.prototype.createLightbox = function (node) {
  let activityIndicatorOn = function () {
      $('<div id="imagelightbox-loading"><div></div></div>').appendTo("body");
    },
    activityIndicatorOff = function () {
      $("#imagelightbox-loading").remove();
    },
    overlayOn = function () {
      let imgWrapper = $('<div class="imagelightbox-wrapper"></div>'),
        imagelightboxOverlay = $(
          '<div id="imagelightbox-overlay" class="imagelightbox"></div>'
        );
      imagelightboxOverlay.append(imgWrapper);
      imagelightboxOverlay.appendTo("body");
    },
    overlayOff = function () {
      $("#imagelightbox-overlay").remove();
    },
    closeButtonOn = function (instance) {
      $(
        '<button type="button" id="imagelightbox-close" title="Close"></button>'
      )
        .appendTo(".imagelightbox")
        .on("click touchend", function () {
          $(this).remove();
          instance.quitImageLightbox();
          return false;
        });
    },
    closeButtonOff = function () {
      $("#imagelightbox-close").remove();
    },
    captionOn = function () {
      let description = $(
        'a[href="' + $("#imagelightbox").attr("src") + '"]'
      ).data("caption");

      if (description && description.length > 0)
        $(
          '<div id="imagelightbox-caption"><div class="imagelightbox-caption">' +
            description +
            "</div></div>"
        ).appendTo(".imagelightbox");
    },
    captionOff = function () {
      $("#imagelightbox-caption").remove();
    },
    arrowsOn = function (instance, selector) {
      if (node.find(selector).length > 1) {
        let $arrows = $(
          '<button type="button" class="imagelightbox-arrow imagelightbox-arrow-left"></button><button type="button" class="imagelightbox-arrow imagelightbox-arrow-right"></button>'
        );

        $arrows.appendTo(".imagelightbox");

        $arrows.on("click touchend", function (e) {
          e.preventDefault();

          let $this = $(this),
            $target = $(
              selector + '[href="' + $("#imagelightbox").attr("src") + '"]'
            ),
            index = $target.index(selector);

          if ($this.hasClass("imagelightbox-arrow-left")) {
            index = index - 1;
            if (!$(selector).eq(index).length) index = $(selector).length;
          } else {
            index = index + 1;
            if (!$(selector).eq(index).length) index = 0;
          }

          instance.switchImageLightbox(index);
          return false;
        });
      }
    },
    arrowsOff = function () {
      $(".imagelightbox-arrow").remove();
    };

  let selector = 'a[data-lightbox="lightbox"]';
  //https://osvaldas.info/examples/image-lightbox-responsive-touch-friendly/
  let instanceF = node.find(selector).imageLightbox({
    onStart: function () {
      overlayOn();
      closeButtonOn(instanceF);
      arrowsOn(instanceF, selector, node);
    },
    onEnd: function () {
      overlayOff();
      captionOff();
      closeButtonOff();
      arrowsOff();
      activityIndicatorOff();
    },
    onLoadStart: function () {
      captionOff();
      activityIndicatorOn();
    },
    onLoadEnd: function () {
      captionOn();
      activityIndicatorOff();
      $(".imagelightbox-arrow").css("display", "block");
    },
  });
};

/***  Faces Slider ***/
NodesManagerFunctions.prototype.sliderFaces = function (node) {
  node.find(".owl-carousel").owlCarousel({
    stagePadding: 15,
    responsive: {
      0: {
        items: 1,
      },
      640: {
        items: 3,
      },
    },
    autoplay: true,
    nav: true,
    dots: false,
    loop: true,
    navElement: ['button aria-label="Change position"'],
    autoplayTimeout: 6000,
    autoplaySpeed: 800,
  });
};

/*** Check Cookie ***/
NodesManagerFunctions.prototype.cookieSetter = function (node) {
  node.find(".bb-button--cookie").on("click", function (event) {
    let ele = $(this),
      action = ele.data("action"),
      link = ele.attr("href");

    if (action != null) {
      event.preventDefault();
    }

    if (action === "accept" && link !== "") {
      $.ajax({
        url: link,
        success: function (data) {
          $(".bb-cookie").remove();
        },
        error: function (data) {
          console.log(data.statusText);
          ele.html("There was an error");
        },
      });
    } else if (action === "decline" && link !== "") {
      $.ajax({
        url: link,
        success: function (data) {
          $(".bb-cookie").remove();
        },
        error: function (data) {
          console.log(data.statusText);
          ele.html("There was an error");
        },
      });
    }
  });
};

/*** Big Slider MOVIE ***/
NodesManagerFunctions.prototype.bigSlider = function (node) {
  node.find(".bb-sliderBig").addClass('owl-carousel')
  //  owl-theme owl-drag');
  let video = node.find('.bb-sliderBig__media'), 
    owl = node.find(".owl-carousel");

  owl.owlCarousel({
    items: 1,
    autoplayTimeout: 5000,
    autoplay: true,
    smartSpeed : 0,
    navText: ['<span><</span>', '<span>></span>'],
    responsive : {
      0: {
        
        nav: true,
        dots: false
      },
      640 : {
        nav: false,
        dots: true
      }
    }
  });

  owl.on('change.owl.carousel', function(e) {
    let item = video[e.item.index]
    if(item.classList.contains('video')) {
      item.pause()
    }
  });

  owl.on('changed.owl.carousel', function(event) {
    let item = video[event.item.index]
    if(item.classList.contains('video')) {
      item.play()
    }
  })
};

let Node = new NodesManagerFunctions();
Node.init();
